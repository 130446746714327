@font-face {
  font-family: 'meo-text-regular';
  src: url('/assets/fonts/meotext-Regular.eot');
  src: url('/assets/fonts/meotext-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/meotext-Regular.woff2') format('woff2'),
      url('/assets/fonts/meotext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'meo-text-bold';
  src: url('/assets/fonts/meotext-Bold.eot');
  src: url('/assets/fonts/meotext-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/meotext-Bold.woff2') format('woff2'),
      url('/assets/fonts/meotext-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'meo-text-extended';
  src: url('/assets/fonts/MEOExtendedRegular.eot');
  src: url('/assets/fonts/MEOExtendedRegular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/MEOExtendedRegular.woff2') format('woff2'),
      url('/assets/fonts/MEOExtendedRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'meo-text-light';
  src: url('/assets/fonts/meotext-Light.eot');
  src: url('/assets/fonts/meotext-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/meotext-Light.woff2') format('woff2'),
      url('/assets/fonts/meotext-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import "fonts";
@import "colors";
@import "mixins";

// App and Global Styles
body {
  box-sizing: border-box;
  font-weight: 600;
  font-style: normal;
  font-family: "meo-text-bold", sans-serif;
  overflow-x: hidden;
}

.app {
  height: 100vh;
  background-color: linear-gradient(157.21deg, #16448A 0%, #A053A0 100%);
}

// Helper Classes
.is-bold {
  font-weight: 700;
}

.button {
  position: relative;
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: linear-gradient(157.21deg, #16448A 0%, #A053A0 100%);
  box-shadow: 6px 26px 26px 0 rgba(80,42,107,0.5);
  color: $white;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  font-family: "meo-text-bold", sans-serif;
  border-radius: 18px;

  font-size: 18px;
  letter-spacing: 3.72px;

  &:disabled {
    color: rgba($white, 0.3);

    span {
      opacity: 0.5;
    }

    cursor: not-allowed;
  }

  // &:hover,
  // &:focus {
  //   &:before {
  //     background-color: darken(linear-gradient(157.21deg, #16448A 0%, #A053A0 100%), 10);
  //   }
  // }
  
  @media screen and (min-width: 1200px) {
    font-size: 26px;
    letter-spacing: 4.3px;
    line-height: 32px;
  }
}

// Copyright
.copyright {
  position: absolute;
  left: 50%;
  bottom: 14px;
  transform: translateX(-50%);

  @include tablet-up {
    left: unset;
    right: 22px;
    bottom: 14px;
  }
}

// Wrapper
.wrapper {
  width: 100%;
  min-height: 100%;
  background-image: url("/assets/bg_mobile.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  padding: 52px 15px;
  display: flex;
  justify-content: center;

  @include tablet-up {
    background-image: url("/assets/bg_desktop.png");
    padding: 130px 50px 50px;
  }
}

// Wrapper Inner
.wrapper__inner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  border-left: 6px solid $white;
  border-right: 6px solid $white;
}

// Wrapper Logos
.wrapper__logo {
  position: relative;

  img {
    height: auto;
    width: 430px;
  }

   @media screen and (min-width: 768px) and (max-width: 991px) {
    img {
      width: 275px;
    }
   }

  @media screen and (max-width: 767px) {
    img {
      width: 160px;
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.wrapper__logo--top {
  top: -110px;

  @include tablet-down() {
    top: -30px;
  }

  @media screen and (max-width: 599px) {
    width: 50%;
    top: 0;
    max-width: 256px;
    transform: translate(-50%, -40%);
    position: absolute;
    left: 50%;
    text-align: center;
  }
}

.wrapper__logo--bottom {
  bottom: 0;
  width: 15%;
  max-width: 26px;
  transform: translate(-50%, 40%);

  @include tablet-up {
    max-width: 44px;
  }
}

// Wrapper Borders
.wrapper__line {
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.wrapper__line--top {
  width: 100%;
  top: 0;

  div {
    width: 15%;
    height: 6px;
    background-color: $white;
  }

  @include tablet-up {
    div {
      width: 39%;
    }
  }

  @media screen and (max-width: 2250px) {
    div {
      width: 37%;
    }
  }

  @media screen and (max-width: 1870px) {
    div {
      width: 35%;
    }
  }

  @media screen and (max-width: 1670px) {
    div {
      width: 33%;
    }
  }

  @media screen and (max-width: 1500px) {
    div {
      width: 28%;
    }
  }

  @media screen and (max-width: 1200px) {
    div {
      width: 19%;
    }
  }
}


.wrapper__line--top--inner {
  width: 100%;
  top: 35px;
  padding: 0 35px;

  div {
    width: 105px;
    height: 5px;
    background-color: $white;
  }

  @include tablet-up {
    div {
      width: 105px;
    }
  }
  @include tablet-down() {
    display: none;
  }
}

.wrapper__line--bottom {
  width: 100%;
  bottom: 0;

  div {
    width: 100%;
    height: 6px;
    background-color: $white;
  }
}

.wrapper__line--bottom--inner {
  width: 100%;
  bottom: 35px;
  padding: 0 35px;

  div {
    width: 105px;
    height: 5px;
    background-color: $white;
  }

  @include tablet-up {
    div {
      width: 105px;
    }
  }
  @include tablet-down() {
    display: none;
  }
}

.wrapper__line--leftInner {
  height: 100%;
  width: 5px;
  left: 35px;

  div {
    position: absolute;
    height: 10%;
    width: 5px;
    background-color: $white;

    &.wrapper__line--top {
      top: 35px;
    }

    &.wrapper__line--bottom {
      bottom: 35px;
    }
  }

  @include tablet-down() {
    display: none;
  }
}

.wrapper__line--rightInner {
  height: 100%;
  width: 5px;
  right: 35px;

  div {
    position: absolute;
    height: 10%;
    width: 5px;
    background-color: $white;

    &.wrapper__line--top {
      top: 35px;
    }

    &.wrapper__line--bottom {
      bottom: 35px;
    }
  }

  @include tablet-down() {
    display: none;
  }
}

.wrapper__line--left {
  left: 0;
}

.wrapper__line--right {
  right: 0;
}

// Form Wrapper
.form__wrapper {
  margin: 20px 20px 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #16448A;
  box-shadow: 0 19px 44px 0 rgba(11,38,79,0.6);

  @media screen and (min-width: 1200px) {
    min-height: 660px;
  }

  @include tablet-up {
    max-width: 1196px;
    margin: 52px 70px 72px;
    padding: 58px;
    flex-direction: row;
  }

  @include tablet-down() {
    width: 92%;
  }
}

.form__fields {
  position: relative;
  width: 100%;
  background-color: $white;
  border-top: 4px solid $white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input__wrapper {
    width: 100%;

    &:first-of-type {
      margin-top: 0px;
    }

    margin: 50px auto 0;
  }

  @include tablet-up {
    align-self: stretch;
    width: 50%;
    border-top: none;
    padding: 50px;

    .input__wrapper {
      margin-top: 32px 0 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 28px;
  }
}

.form__fields__inner {
  position: relative;
  margin-bottom: 42px;
}

.form__feedback {
  h1 {
    margin-top: 0;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 57px;
  }

  p {
    font-family: "meo-text-regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  @include tablet-up {
    h1 {
      font-size: 42px;
      letter-spacing: 4.49px;
      line-height: 40px;
      margin-bottom: 63px;
    }

    p {
      font-size: 16px;
      letter-spacing: 3px;
      line-height: 32px;
    }
  }

  @media screen and (min-width: 1300px) {
     p {
       font-size: 20px;
       letter-spacing: 3px;
       line-height: 32px;
     }
  }

  @media screen and (max-width: 1199px) {
     p {
       font-size: 14px;
       letter-spacing: 1.75px;
       line-height: 21px;
     }
   }
  
  @include tablet-down() {
    h1, p {
      text-align: center;
    }
  }
}

.form__text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  color: $black;
  text-align: center;
  max-width: 260px;
  margin: 0 auto 24px;
  font-family: "meo-text-regular", sans-serif;
  text-transform: uppercase;

  @include tablet-up {
    text-align: left;
    font-size: 21px;
    letter-spacing: 3px;
    line-height: 32px;
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1199px) {
    font-size: 14px;
    letter-spacing: 1.75px;
    line-height: 21px;
  }

  @media screen and (max-width: 960px) {
    font-size: 17px;
    letter-spacing: 1.75px;
    line-height: 23px;
  }
}

.form__player {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 225px;
  min-height: 180px;

  img:not(.frame-inner) {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  @include tablet-up {
    margin-right: 72px;

    img:not(.frame-inner) {
      width: 640px;
      left: -100px;
      bottom: -59px;
    }

    .frame-inner {
      width: 100%;
      height: auto;
    }

    &:before {
      display: none;
    }
  }

  @include desktop-down {
    img:not(.frame-inner) {
      width: 620px;
      left: -110px;
    }
  }

  @media screen and (max-width: 1199px) {
    img:not(.frame-inner) { width: 470px }
  }

  @include tablet-down() {
    width: 100%;

    .frame-inner {
      width: calc(100% - 50px);
      height: 90vw;
    }

    img:not(.frame-inner) { 
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 100%;  
    }
  }

  @media screen and (min-width: 570px) and (max-width: 955px) {
    .frame-inner {
      width: calc(100% - 80px);
      height: 92vw;
    }
  }
}

.button--send {
  width: 100%;
}

.button--back {
  margin-top: 2rem;
  width: 100%;

  @include tablet-up {
    font-size: 20px;
    padding: 1rem 1rem;
    margin-top: 63px;
  }
}

.input__wrapper {
  position: relative;
}

.input__wrapper--success {
  .input {
    caret-color: $purple;
    border-color: $purple;

    &:focus,
    &:active {
      border-color: $purple;
      outline-color: $purple;
    }
  }
  .input__message {
    color: $purple;
  }
}

.input__wrapper--error {
  .input {
    caret-color: $red;
    border-color: $red;

    &:focus,
    &:active {
      border-color: $red;
      outline-color: $red;
    }
  }
  .input__message {
    color: $red;
  }
}

.input {
  width: 100%;
  padding: 0 20px;
  background-color: $white;
  border: 3px solid $black;
  border-radius: 13px;
  color: $black;
  font-size: 16px;
  letter-spacing: 2px;
  height: 60px;
  -webkit-appearance: none;
  text-transform: uppercase;

  &:focus,
  &:active {
    border-color: $purple;
    outline-color: $purple;
    outline: 0;
  }

  @include tablet-up {
    border-width: 3px;
    font-size: 20px;
    letter-spacing: 2.5px;
  }

  @media screen and (max-width: 1199px) {
     font-size: 16px;
     letter-spacing: 2px;
  }
}

.input__message {
  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  display: block;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase; 
}

.input__label {
  display: block;
  font-size: 11px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @include tablet-up {
    font-size: 18px;
    letter-spacing: 4px;
  }

  @media screen and (max-width: 1199px) {
    font-size: 11px;
    letter-spacing: 2px;
  }
}

.player {
  position: absolute;
  max-width: 35%;
  bottom: 0;
  left: -30%;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }

  @include tablet-up {
    max-width: 25%;
    bottom: -40px;
    left: -40px;
  }
}

.project-name {
  font-size: 23px;
  letter-spacing: 2.32px;
  line-height: 18px;
  color: $white;
  text-transform: uppercase;
  margin: 70px auto 23px;
  font-family: "meo-text-bold", sans-serif;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 30px;
    line-height: 23px;
    margin: 50px auto 53px;
  }

  @include tablet-up() {
    font-size: 46px;
    letter-spacing: 5.01px;
    line-height: 40px;
    margin: 0 auto 56px;
  }

   @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 30px;
      line-height: 23px;
      margin: 70px auto 53px;
   }

    @media screen and (min-width: 576px) and (max-width: 600px) {
      margin: 100px auto 53px;
    }
}

// Responsive Mixins

@mixin tablet-up {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: 1399px) {
    @content;
  }
}
